import { Navigate } from "react-router-dom";

import app from "../firebase";
import {getAuth} from "firebase/auth";
import React from "react";
const auth = getAuth(app);

export const ProtectedRoute = ({ children }: {children: any}) => {
  const user = auth.currentUser;
  if (!user) {
    return <Navigate replace to="/login" />
  }
  return children;
};
