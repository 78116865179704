import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import '@elastic/eui/dist/eui_theme_light.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import app from './firebase';

import { EuiProvider } from '@elastic/eui';
import {getAnalytics} from "firebase/analytics";
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import AdminPage from "./pages/AdminPage";
import FormPage from "./pages/FormPage";
import {ProtectedRoute} from "./components/ProtectedRoute";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage header={{pageTitle: 'LARGE RÉSEAU DE SOINS CHANAS ASSURANCES S.A'}} />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/admin",
    element: <ProtectedRoute><AdminPage header={{pageTitle: 'Administration'}} /></ProtectedRoute>,
  },
  {
    path: "/admin/:id/edit",
    element: <ProtectedRoute><FormPage header={{pageTitle: 'Édition'}} /></ProtectedRoute>,
  },
  {
    path: "/admin/add",
    element: <ProtectedRoute><FormPage header={{pageTitle: 'Ajouter'}} /></ProtectedRoute>,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <EuiProvider colorMode="light">
      <RouterProvider router={router} />
    </EuiProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

getAnalytics(app);
