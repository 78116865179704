import React, {Component, ReactElement} from 'react';
import {
  EuiButton,
  EuiFieldPassword,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiPageSidebarProps,
  EuiPageTemplate,
  EuiPageTemplateProps,
  EuiSpacer,
  EuiTextColor,
} from '@elastic/eui';
import {getAuth, signInWithEmailAndPassword} from "firebase/auth";
import app from "../firebase";
import {Navigate} from 'react-router-dom';

const auth = getAuth(app);

export interface LoginPageProps {
  sidebar?: ReactElement;
  emptyPrompt?: ReactElement;
  panelled?: EuiPageTemplateProps['panelled'];
  // For fullscreen only
  sidebarSticky?: EuiPageSidebarProps['sticky'];
  offset?: EuiPageTemplateProps['offset'];
  grow?: EuiPageTemplateProps['grow'];
}

export interface LoginPageState {
  email?: string;
  password?: string;
  ongoing?: boolean;
  error?: string;
  authenticated?: boolean;
}

class LoginPage extends Component<LoginPageProps, LoginPageState> {

  constructor(props: LoginPageProps) {
    super(props);

    this.state = {}
  }

  onSubmit = async (e: any) => {
    e.preventDefault();
    const {email, password} = this.state;
    if (!email || !password) {
      this.setState({error: 'Email or password is required!'})
    }

    try {
      this.setState({error: undefined, ongoing: true})
      await signInWithEmailAndPassword(auth, email || '', password || '');
      this.setState({authenticated: true, ongoing: false});
    } catch(e: any) {
      this.setState({error: e.message, ongoing: false})
    }
  }

  render() {
    const {
      emptyPrompt = <></>,
      panelled,
      offset,
      grow,
    } = this.props;
    const {ongoing, error, authenticated} = this.state;

    if (authenticated) {
      return <Navigate replace to="/admin" />
    }

    return (
      <EuiPageTemplate panelled={panelled} offset={offset} grow={grow}>
        <EuiPageTemplate.EmptyPrompt
          title={<span>Login</span>}
          footer={undefined}
        >
          <EuiForm component="form" onSubmit={this.onSubmit} method={'post'}>
            {error ? <EuiTextColor color="danger">{error}</EuiTextColor> : null}
            <EuiFormRow label="Email">
              <EuiFieldText name="email" placeholder={'Your email address'} onChange={(e) => this.setState({email: e.target.value})} />
            </EuiFormRow>
            <EuiFormRow label="Password">
              <EuiFieldPassword name="password" type={'dual'} placeholder={'Your password'} onChange={(e) => this.setState({password: e.target.value})} />
            </EuiFormRow>
            <EuiSpacer />

            <EuiButton type="submit" fill isLoading={ongoing}>
              Login
            </EuiButton>
          </EuiForm>
        </EuiPageTemplate.EmptyPrompt>
      </EuiPageTemplate>
    );
  }
}

export default LoginPage;
