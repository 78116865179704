import React, {Fragment, ReactElement, useEffect, useState} from 'react';
import {
  EuiButton,
  EuiFieldNumber,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiImage,
  EuiPageHeaderProps,
  EuiPageTemplate,
  EuiSpacer,
  EuiTextColor
} from "@elastic/eui";
import {doc, getDoc, getFirestore, setDoc} from "firebase/firestore";
import app from "../firebase";
import {Navigate, useParams} from 'react-router-dom';

const db = getFirestore(app);

export interface FormPageProps {
  button?: ReactElement;
  emptyPrompt?: ReactElement;
  header?: EuiPageHeaderProps;
}

export function uuidv4(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

const FormPage = ({header, button}: FormPageProps) => {
  let { id } = useParams();
  const [data, setData] = useState<Record<string, any>>({});
  const [saving, setSaving] = useState<boolean>(false);
  const [saved, setSaved] = useState<boolean>(false);
  const [error, setError] = useState<string|undefined>(undefined);
  useEffect(() => {
    if (id) {
      getDoc<Record<string, any>>(doc(db, "fosas", id)).then(snap => {
        setData(snap.data() || {});
      })
    }
  }, [])

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setSaving(true);
    try {
      await setDoc(doc(db, "fosas", id || uuidv4()), data);
      setSaving(false);
      setSaved(true);
    } catch (e: any) {
      setError(e.message);
      setSaving(false);
    }
  }

  if (saved) {
    return <Navigate replace to="/admin" />
  }

  return (
    <EuiPageTemplate panelled={true} offset={10} grow={true}>
      <EuiPageTemplate.Sidebar sticky={false}>
        <EuiImage
          size="l"
          hasShadow={false}
          alt={'FOSA Network'}
          src={require('../fosa-network.jpeg')}
        />
      </EuiPageTemplate.Sidebar>
      {header && (
        <EuiPageTemplate.Header {...header} iconType={require('../logo.png')} rightSideItems={[button]} />
      )}
      <EuiPageTemplate.Section>
        <Fragment>
          <EuiForm component="form" onSubmit={onSubmit} method={'post'}>
            {error ? <EuiTextColor color="danger">{error}</EuiTextColor> : null}

            <EuiFormRow label="Nom" helpText="Le nom de la FOSA">
              <EuiFieldText name="name" value={data.name} onChange={(e) => setData({...data, name: e.target.value.toUpperCase()})} />
            </EuiFormRow>

            <EuiFormRow label="Ville" helpText="La ville où est située la FOSA">
              <EuiFieldText name="town" value={data.town} onChange={(e) => setData({...data, town: e.target.value.toUpperCase()})} />
            </EuiFormRow>

            <EuiFormRow label="Adresse" helpText="La location ou adresse de la FOSA">
              <EuiFieldText name="location" value={data.location} onChange={(e) => setData({...data, location: e.target.value})} />
            </EuiFormRow>

            <EuiFormRow label="Catégorie" helpText="La catégorie à laquelle appartient la FOSA">
              <EuiFieldText name="type" value={data.type} onChange={(e) => setData({...data, type: e.target.value.toUpperCase()})} />
            </EuiFormRow>

            <EuiFormRow label="Contacts" helpText="Les numéros de téléphone de contact">
              <EuiFieldText name="contact" value={data.contact} onChange={(e) => setData({...data, contact: e.target.value})} />
            </EuiFormRow>

            <EuiFormRow label="Longitude" helpText="Longitude GPS">
              <EuiFieldNumber name="longitude" value={data.longitude} onChange={(e) => setData({...data, longitude: e.target.value})} />
            </EuiFormRow>

            <EuiFormRow label="Latitude" helpText="Latitude GPS">
              <EuiFieldNumber name="latitude" value={data.latitude} onChange={(e) => setData({...data, latitude: e.target.value})} />
            </EuiFormRow>

            <EuiSpacer />

            <EuiButton type="submit" fill isLoading={saving}>
              Enregistrer
            </EuiButton>
          </EuiForm>
        </Fragment>
      </EuiPageTemplate.Section>
    </EuiPageTemplate>
  )
};

export default FormPage;
