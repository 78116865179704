import React, {Component, Fragment, ReactElement} from 'react';
import {
  EuiBasicTable,
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
  EuiLink,
  EuiPageHeaderProps,
  EuiPageTemplate,
  EuiSelect,
  EuiSpacer
} from "@elastic/eui";
import {EuiSelectOption} from "@elastic/eui/src/components/form/select/select";
import {collection, getDocs, getFirestore} from "firebase/firestore";
import app from "../firebase";
import {FosaInterface} from '../types';

const db = getFirestore(app);

export interface HomePageProps {
  button?: ReactElement;
  emptyPrompt?: ReactElement;
  header?: EuiPageHeaderProps;
}

export interface HomePageState {
  filter: {search?: string; town?: string; type?: string};
  towns: string[];
  types: string[];
  pageIndex: number;
  pageSize: number;
  data: FosaInterface[];
  showPerPageOptions: boolean;
}

class HomePage extends Component<HomePageProps, HomePageState> {
  constructor(props: HomePageProps) {
    super(props);

    this.state = {
      pageIndex: 0,
      pageSize: 10,
      towns: [],
      types: [],
      filter: {},
      showPerPageOptions: true,
      data: []
    }
  }

  componentDidMount() {
    // @ts-ignore
    getDocs<FosaInterface>(collection(db, 'fosas')).then(snapshot => {
      const data = snapshot.docs.map(doc => doc.data());
      const towns = Array.from(new Set(data.map(d => d.town)));
      const types = Array.from(new Set(data.filter(d => d.type?.length > 0).map(d => d.type)));
      this.setState({data, towns, types})
    });
  }


  render() {
    const {header, button} = this.props;
    const {filter, towns, types, pageIndex, pageSize, showPerPageOptions, data} = this.state;

    const columns = [
      {
        field: 'name',
        name: 'Nom',
        sortable: true,
        'data-test-subj': 'nameCell',
        mobileOptions: {
          render: (item: FosaInterface) => (
            <span>
            <EuiLink href="#" target="_blank">
              {item.name}
            </EuiLink>
          </span>
          ),
          header: false,
          truncateText: false,
          enlarge: true,
          width: '100%',
        },
      },
      {
        field: 'location',
        name: 'Lieu',
        width: '200px',
      },
      {
        field: 'type',
        name: 'Type',
        width: '200px',
      },
      {
        field: 'contact',
        name: 'Contact',
        width: '200px',
      },
      {
        field: 'geolocation',
        name: 'Géolocation',
        width: '120px',
        render: (value: string, record: FosaInterface) => {
          if (record.latitude && record.latitude > 0) {
            return (
              <EuiLink href={`https://www.google.com/maps/?q=${record.latitude},${record.longitude}`} target="_blank">
                Ouvrir la Map
              </EuiLink>
            )
          }
          return null
        },
      },
    ];
    const getRowProps = (item: FosaInterface) => {
      const { name } = item;
      return {
        'data-test-subj': `row-${name}`,
        className: 'customRowClass',
        onClick: () => {},
      };
    };
    const getCellProps = (item: FosaInterface) => {
      const { name } = item;
      // const { field } = column;
      return {
        className: 'customCellClass',
        'data-test-subj': `cell-${name}`,
        textOnly: true,
      };
    };

    let items: FosaInterface[] = data;
    if (filter.town && filter.town.length > 0) {
      items = items.filter(i => i.town === filter.town)
    }
    if (filter.type && filter.type.length > 0) {
      items = items.filter(i => i.type === filter.type)
    }
    if (filter.search && filter.search.length > 0) {
      const regex = new RegExp(filter.search.trim(), 'i');
      items = items.filter(i => regex.test(i.name))
    }

    const onTableChange = ({ page = {} }: any) => {
      const { index: pageIndex, size: pageSize } = page;

      this.setState({pageIndex, pageSize});
    };

    const totalItemCount = items.length;
    const pagination = {
      pageIndex,
      pageSize,
      totalItemCount,
      pageSizeOptions: [10, 0],
      showPerPageOptions,
    };

    return (
      <EuiPageTemplate panelled={true} offset={10} grow={true}>
        <EuiPageTemplate.Sidebar sticky={false}>
          <EuiImage
            size="l"
            hasShadow={false}
            alt={'FOSA Network'}
            src={require('../fosa-network.jpeg')}
          />
        </EuiPageTemplate.Sidebar>
        {header && (
          <EuiPageTemplate.Header {...header} iconType={require('../logo.png')} rightSideItems={[button]} />
        )}
        {/*<EuiPageTemplate.Section grow={false} bottomBorder={'extended'}>
        <EuiText textAlign="center">
          <strong>
            Stack EuiPageTemplate sections and headers to create your custom
            content order.
          </strong>
        </EuiText>
      </EuiPageTemplate.Section>*/}
        <EuiPageTemplate.Section>
          <Fragment>
            <EuiFlexGroup alignItems="center">
              <EuiFlexItem grow={true}>
                <EuiFieldSearch
                  placeholder="Rechercher..."
                  value={filter.search}
                  onChange={(e) => this.setState({filter: {...filter, search: e.target.value}})}
                  isClearable={true}
                />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiSelect
                  // id={basicSelectId}
                  options={[{text: "Filtrer par type", value: ""}, ...types.map(t => ({value: t, text: t}))] as EuiSelectOption[]}
                  value={undefined}
                  onChange={(e) => this.setState({filter: {...filter, type: e.target.value}})}
                />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiSelect
                  // id={basicSelectId}
                  options={[{text: "Filtrer part ville", value: ""}, ...towns.map(t => ({value: t, text: t}))] as EuiSelectOption[]}
                  value={undefined}
                  onChange={(e) => this.setState({filter: {...filter, town: e.target.value}})}
                />
              </EuiFlexItem>
              <EuiFlexItem />
            </EuiFlexGroup>

            <EuiSpacer size="l" />

            <EuiBasicTable
              tableCaption="FOSA List"
              items={items.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)}
              rowHeader="name"
              columns={columns}
              rowProps={getRowProps}
              cellProps={getCellProps}
              pagination={pagination}
              onChange={onTableChange}
            />
          </Fragment>
        </EuiPageTemplate.Section>
      </EuiPageTemplate>
    )
  }
}

export default HomePage;
