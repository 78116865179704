import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBr7c1o6eBpGXiJMJspsHE__F0Rz4spXTg",
  authDomain: "chanas-assurances.firebaseapp.com",
  projectId: "chanas-assurances",
  storageBucket: "chanas-assurances.appspot.com",
  messagingSenderId: "600099328774",
  appId: "1:600099328774:web:625208c2523421c1cd4487",
  measurementId: "G-E8FG1FZNER"
};

export default initializeApp(firebaseConfig);
